#wpadminbar {
  position: fixed !important;
  overflow: hidden;
}


// Style A
body {

  @media (min-width: 1025px) {
    // Nav A +  Top Bar Static
    &.nav_style_a.top_bar_desktop_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav A  + Top Bar Fixed
    &.nav_style_a.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);

        &.active {
          top: 56px;
          position: fixed;
        }
      }
    }

    // Nav A + Admin Bar + Top Bar Fixed
    &.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }

    // Nav A + Admin Bar + Top Bar Static
    &.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }
  }

  // Desktop
  @media (min-width: 783px) {

    // Nav A Fixed
    &.nav_style_a.desktop_nav_fixed {
      padding-top: calc(100px);

      .desktop-navigation[data-style="a"] {
        top: 0;

        &.active {
          top: 0;
          position: fixed;
        }
      }
    }

    // Nav A Static
    &.nav_style_a.desktop_nav_static {
      padding-top: calc(100px);

      .desktop-navigation[data-style="a"] {
        top: 0;
      }
    }

    // Nav A  + Admin Bar
    &.nav_style_a.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="a"] {
        top: calc(32px);
      }
    }

    // Nav A Fixed + Admin Bar
    &.nav_style_a.desktop_nav_fixed.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="a"] {
        top: calc(32px);
      }
    }

    // Nav A +  Top Bar Static
    &.nav_style_a.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav A  + Top Bar Fixed
    &.nav_style_a.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);

        &.active {
          top: 56px;
          position: fixed;
        }
      }
    }

    // Nav A + Admin Bar + Top Bar Fixed
    &.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }

    // Nav A + Admin Bar + Top Bar Static
    &.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }
  }

  @media (max-width: 1024px) {

    // Nav A Fixed + Top Bar Mobile Static + Admin Bar
    &.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 100px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }

    }

  }

  // Mobile
  @media (max-width: 782px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="a"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav A
    &.nav_style_a {
      padding-top: calc(100px);

      .desktop-navigation[data-style="a"] {
        top: 0;
      }
    }

    // Nav A  + Admin Bar
    &.nav_style_a.admin-bar {
      padding-top: calc(100px + 46px);

      .desktop-navigation[data-style="a"] {
        top: calc(46px);
      }
    }

    // Nav A +  Top Bar Static
    &.nav_style_a.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav A  + Top Bar Fixed
    &.nav_style_a.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(56px);
      }
    }

    // Nav A + Admin Bar + Top Bar Static
    &.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(32px + 56px);

        &.active {
          top: 46px;
        }
      }
    }

    // Nav A + Admin Bar + Top Bar Fixed
    &.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(46px + 56px);
      }
    }


    // Nav A Fixed + Top Bar Mobile Static + Admin Bar
    &.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
      padding-top: calc(46px + 56px + 100px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="a"] {
        top: calc(46px + 56px);

        &.active {
          top: 46px;
        }
      }

    }

  }

}


// Style E
body {

  // Desktop
  @media (min-width: 1025px) {

    // Nav E  Fixed
    &.nav_style_e.desktop_nav_fixed {
      padding-top: 100px;

      .desktop-navigation[data-style="e"] {
        &.active {
          top: 0;
          position: fixed;
        }
      }
    }

    // Nav E  Static
    &.nav_style_e.desktop_nav_static {
      padding-top: calc(100px);

      .desktop-navigation[data-style="e"] {
        top: 0;
      }
    }

    // Nav E   + Admin Bar
    &.nav_style_e.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="e"] {
        top: calc(32px);
      }
    }

    // Nav E Fixed + Admin Bar
    &.nav_style_e.desktop_nav_fixed.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="e"] {
        top: calc(32px);
      }
    }

    // Nav E  +  Top Bar Static
    &.nav_style_e.top_bar_desktop_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav E   + Top Bar Fixed
    &.nav_style_e.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);
      }
    }

    // Nav E  + Admin Bar + Top Bar Static
    &.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }

    // Nav E  + Admin Bar + Top Bar Fixed
    &.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(32px + 56px);
      }
    }


  }

  @media (max-width: 1024px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="e"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav E
    &.nav_style_e {
      padding-top: calc(100px);

      .desktop-navigation[data-style="e"] {
        top: 0;
      }
    }

    // Nav E  + Admin Bar
    &.nav_style_e.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="e"] {
        top: calc(32px);
      }
    }

    // Nav E +  Top Bar Static
    &.nav_style_e.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav E  + Top Bar Fixed
    &.nav_style_e.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);
      }
    }

    // Nav E + Admin Bar + Top Bar Static
    &.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }

    // Nav E + Admin Bar + Top Bar Fixed
    &.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(32px + 56px);
      }
    }

  }

  // Mobile
  @media (max-width: 782px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="e"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav E
    &.nav_style_e {
      padding-top: calc(100px);

      .desktop-navigation[data-style="e"] {
        top: 0;
      }
    }

    // Nav E   + Admin Bar
    &.nav_style_e.admin-bar {
      padding-top: calc(100px + 46px);

      .desktop-navigation[data-style="e"] {
        top: calc(46px);
      }
    }

    // Nav E  +  Top Bar Static
    &.nav_style_e.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav E   + Top Bar Fixed
    &.nav_style_e.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(56px);
      }
    }

    // Nav E  + Admin Bar + Top Bar Static
    &.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(32px + 56px);

        &.active {
          top: 46px;
        }
      }
    }

    // Nav E  + Admin Bar + Top Bar Fixed
    &.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="e"] {
        top: calc(46px + 56px);
      }
    }


  }
}


// Style B
body {

  // Desktop
  @media (min-width: 1025px) {

    // Nav B Static Above
    &.nav_style_b.desktop_nav_static.b_nav_position_above {
      padding-top: calc(50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: 0;
      }

      .nav-header[data-style="b"] {
        top: 50px;
      }

    }

    // Nav B Static Below
    &.nav_style_b.desktop_nav_static.b_nav_position_below {
      padding-top: calc(50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(254px);
      }

      .nav-header[data-style="b"] {
        top: 0;
      }
    }


    // Nav B Fixed Above
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above {
      padding-top: calc(50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: 0;

        &.active {
          top: 0;
          position: fixed;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px)
      }
    }

    // Nav B Fixed Below
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below {
      padding-top: calc(50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: 254px;

        &.active {
          position: fixed;
          top: 0;
        }
      }

      .nav-header[data-style="b"] {
        top: 0;
      }
    }


    // Nav B Fixed Above + Top Bar Fixed
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: 56px;

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px);
      }
    }

    // Nav B Fixed Below + Top Bar Fixed
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px);

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(56px);
      }
    }


    // Nav B Fixed Above + Top Bar Static
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: 56px;

        &.active {
          position: fixed;
          top: 0;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px);
      }
    }


    // Nav B Static Below + Top Bar Fixed
    &.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px);

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(56px);
      }
    }


    // Nav B Static Above + Admin Bar
    &.nav_style_b.desktop_nav_static.b_nav_position_above.admin-bar {
      padding-top: calc(32px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(32px)
      }

      .nav-header[data-style="b"] {
        top: calc(32px + 50px);
      }

    }


    // Nav B Fixed Above + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above.admin-bar {
      padding-top: calc(32px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(32px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(32px + 50px);
      }
    }

    // Nav B Static Below + Admin Bar
    &.nav_style_b.desktop_nav_static.b_nav_position_below.admin-bar {
      padding-top: calc(32px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 254px)
      }

      .nav-header[data-style="b"] {
        top: 32px;
      }

    }


    // Nav B Fixed Below + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.admin-bar {
      padding-top: calc(32px + 50px + 254px);

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 254px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="b"] {
        top: 32px;
      }
    }


    // Nav B Static Above + Top Bar Fixed | Fixed
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed,
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 254px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px);
      }

      .nav-header[data-style="b"] {
        top: 56px;
      }

    }


    // Nav B Static Above + Top Bar Fixed | Fixed + Admin Bar
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar,
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px + 32px);
      }

      .nav-header[data-style="b"] {
        top: calc(56px + 32px);
      }

    }


    // Nav B Fixed Above + Top Bar Fixed + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 32px);

        &.active {
          top: calc(56px + 32px);
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px + 32px);
      }
    }

    // Nav B Fixed Above + Top Bar Static + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 32px);

        &.active {
          top: calc(32px);
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px + 32px);
      }
    }


    // Nav B Fixed Below + Top Bar Static + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 32px);

        &.active {
          top: calc(32px);
        }
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px + 32px);
      }
    }


    // Nav B Fixed Below + Top Bar Static
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 254px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px);

        &.active {
          top: 0;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(56px);
      }

    }


    // Nav B Fixed Below + Top Bar Fixed
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 254px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px);

        &.active {
          top: 56px;
        }
      }

      .nav-header[data-style="b"] {
        top: calc(56px);
      }
    }


    // Nav B Fixed Below + Top Bar Statoc | Fixed + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar,
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px + 254px + 32px);

        &.active {
          top: calc(32px);
        }
      }

      .nav-header[data-style="b"] {
        top: calc(56px + 32px);
      }

    }


    // Nav B Static Below + Top Bar Static
    &.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 254px + 50px);

      .top-bar {
        top: 0
      }

      .desktop-navigation[data-style="b"] {
        top: calc(254px + 56px)
      }

      .nav-header[data-style="b"] {
        top: calc(56px);
      }
    }

    // Nav B Static Below + Top Bar Static + Admin Bar
    &.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 254px + 56px)
      }

      .nav-header[data-style="b"] {
        top: calc(32px + 56px);
      }
    }


    // Nav B Static Above + Top Bar Static
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 254px + 50px);

      .top-bar {
        top: 0
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px)
      }

      .nav-header[data-style="b"] {
        top: calc(50px + 56px);
      }
    }

    // Nav B Static Above + Top Bar Static + Admin Bar
    &.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 56px)
      }

      .nav-header[data-style="b"] {
        top: calc(32px + 50px + 56px);
      }
    }

    // Nav B Fixed Below + Top Bar Fixed + Admin Bar
    &.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 254px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 56px + 254px);

        &.active {
          top: calc(32px + 56px);
        }
      }

      .nav-header[data-style="b"] {
        top: calc(32px + 56px);
      }
    }

  }

  // Tablet
  @media (max-width: 1024px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="b"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav B
    &.nav_style_b {
      padding-top: calc(100px);

      .desktop-navigation[data-style="b"] {
        top: 0;
      }
    }

    // Nav B  + Admin Bar
    &.nav_style_b.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="b"] {
        top: calc(32px);
      }
    }

    // Nav B +  Top Bar Static
    &.nav_style_b.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav B  + Top Bar Fixed
    &.nav_style_b.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px);
      }
    }

    // Nav B + Admin Bar + Top Bar Static
    &.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }

    // Nav B + Admin Bar + Top Bar Fixed
    &.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(32px + 56px);
      }
    }


  }

  // Mobile
  @media (max-width: 782px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="b"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav B
    &.nav_style_b {
      padding-top: calc(100px);

      .desktop-navigation[data-style="b"] {
        top: 0;
      }
    }

    // Nav B  + Admin Bar
    &.nav_style_b.admin-bar {
      padding-top: calc(100px + 46px);

      .desktop-navigation[data-style="b"] {
        top: calc(46px);
      }
    }

    // Nav B +  Top Bar Static
    &.nav_style_b.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav B  + Top Bar Fixed
    &.nav_style_b.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(56px);
      }
    }

    // Nav B + Admin Bar + Top Bar Static
    &.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(46px + 56px);

        &.active {
          top: 46px;
        }
      }
    }

    // Nav B + Admin Bar + Top Bar Fixed
    &.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(46px + 56px);
      }
    }


    // Nav Fixed + Top bar Fixed + Admin Bar
    &.nav_style_b.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar {
      padding-top: calc(46px + 56px + 100px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="b"] {
        top: calc(46px + 56px);

        &.active {
          top: calc(46px + 56px);
        }
      }
    }


  }

}


// Style C
body {

  // Desktop
  @media (min-width: 1025px) {

    // Nav C Static Above
    &.nav_style_c.desktop_nav_static.c_nav_position_above {
      padding-top: calc(50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: 0;
      }

      .nav-header[data-style="c"] {
        top: 50px;
      }

    }

    // Nav C Static Below
    &.nav_style_c.desktop_nav_static.c_nav_position_below {
      padding-top: calc(50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(140px);
      }

      .nav-header[data-style="c"] {
        top: 0;
      }
    }


    // Nav C Fixed Above
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above {
      padding-top: calc(50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: 0;

        &.active {
          top: 0;
          position: fixed;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(50px)
      }
    }

    // Nav C Fixed Below
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below {
      padding-top: calc(50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: 140px;

        &.active {
          position: fixed;
          top: 0;
        }
      }

      .nav-header[data-style="c"] {
        top: 0;
      }
    }


    // Nav C Fixed Above + Top Bar Fixed
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: 56px;

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(50px + 56px);
      }
    }

    // Nav C Fixed Below + Top Bar Fixed
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 140px);

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(56px);
      }
    }


    // Nav C Static Below + Top Bar Fixed
    &.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 140px);

        &.active {
          position: fixed;
          top: 56px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(56px);
      }
    }


    // Nav C Static Below + Top Bar Fixed + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 50px + 140px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px + 140px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(32px + 56px);
      }
    }

    // Nav C Static Above + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_above.admin-bar {
      padding-top: calc(32px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(32px)
      }

      .nav-header[data-style="c"] {
        top: calc(32px + 50px);
      }

    }

    // Nav C Fixed Above + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above.admin-bar {
      padding-top: calc(32px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(32px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(32px + 50px);
      }
    }

    // Nav C Static Below + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_below.admin-bar {
      padding-top: calc(32px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 140px)
      }

      .nav-header[data-style="c"] {
        top: 32px;
      }

    }

    // Nav C Fixed Below + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below.admin-bar {
      padding-top: calc(32px + 50px + 140px);

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 140px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="c"] {
        top: 32px;
      }
    }

    // Nav C Static Above + Top Bar  Fixed
    &.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 140px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 50px);
      }
    }

    // Nav C Static Above + Top Bar  Static
    &.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 140px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 50px);
      }

    }

    // Nav C Static Above + Top Bar Fixed  + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 32px);
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 32px + 50px);
      }
    }

    // Nav C Static Above + Top Bar Static  + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 32px);
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 32px + 50px);
      }

    }

    // Nav C Fixed Above + Top Bar  Fixed + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 32px);

        &.active {
          position: fixed;
          top: calc(56px + 32px);
        }
      }

      .nav-header[data-style="c"] {
        top: calc(50px + 56px + 32px);
      }
    }


    // Nav C Fixed Above + Top Bar Static  + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 32px);

        &.active {
          position: fixed;
          top: 32px;
        }
      }

      .nav-header[data-style="c"] {
        top: calc(50px + 56px + 32px);
      }
    }


    // Nav C Fixed Above + Top Bar Static
    &.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 140px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: 56px;

        &.active {
          position: fixed;
          top: 0
        }
      }

      .nav-header[data-style="c"] {
        top: calc(50px + 56px);
      }

    }

    // Nav C Static Below + Top Bar Static
    &.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 50px + 140px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(140px + 56px)
      }

      .nav-header[data-style="c"] {
        top: calc(56px)
      }

    }

    // Nav C Static Below + Top Bar Static + Admin Bar
    &.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 50px + 140px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 140px + 56px)
      }

      .nav-header[data-style="c"] {
        top: calc(32px + 56px)
      }

    }

    // Nav C Fixed Below + Top Bar Fixed  + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 140px + 32px);

        &.active {
          top: calc(56px + 32px);
        }
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 32px);
      }

    }

    // Nav C Fixed Below + Top Bar Static
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 140px + 50px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 140px);

        &.active {
          top: 0
        }
      }

      .nav-header[data-style="c"] {
        top: calc(56px);
      }

    }

    // Nav C Fixed Below + Top Bar Static + Admin Bar
    &.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 140px + 50px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px + 140px + 32px);

        &.active {
          top: calc(32px);
        }
      }

      .nav-header[data-style="c"] {
        top: calc(56px + 32px);
      }

    }
  }


  // Tablet
  @media (max-width: 1024px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="c"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav C
    &.nav_style_c {
      padding-top: calc(100px);

      .desktop-navigation[data-style="c"] {
        top: 0;
      }
    }

    // Nav C  + Admin Bar
    &.nav_style_c.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="c"] {
        top: calc(32px);
      }
    }

    // Nav C +  Top Bar Static
    &.nav_style_c.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav C  + Top Bar Fixed
    &.nav_style_c.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);
      }
    }

    // Nav C + Admin Bar + Top Bar Static
    &.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }

    // Nav C + Admin Bar + Top Bar Fixed
    &.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px);
      }
    }

    // Nav C Fixed + Top Bar Mobile Static + Admin Bar
    &.nav_style_c.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 100px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }

    }


    // Nav C + Admin Bar + Top Bar Fixed
    &.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px);
      }
    }


    // Nav C Static + Top Bar Fixed + Admin Bar
    &.nav_style_c.desktop_nav_static.top_bar_mobile_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 100px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(32px + 56px);
      }
    }

  }

  // Mobile
  @media (max-width: 782px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="c"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav C
    &.nav_style_c {
      padding-top: calc(100px);

      .desktop-navigation[data-style="c"] {
        top: 0;
      }
    }

    // Nav C  + Admin Bar
    &.nav_style_c.admin-bar {
      padding-top: calc(100px + 46px);

      .desktop-navigation[data-style="c"] {
        top: calc(46px);
      }
    }

    // Nav C +  Top Bar Static
    &.nav_style_c.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav C  + Top Bar Fixed
    &.nav_style_c.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(56px);
      }
    }

    // Nav C + Admin Bar + Top Bar Static
    &.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(46px + 56px);

        &.active {
          top: 46px;
        }
      }
    }

    // Nav C + Admin Bar + Top Bar Fixed
    &.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="c"] {
        top: calc(46px + 56px);
      }
    }


  }

}


// Style D
body {

  // Desktop
  @media (min-width: 1025px) {

    // Nav D Fixed Above
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above {
      padding-top: 82px;

      .desktop-navigation[data-style="d"] {
        top: 0;
        position: absolute;

        &.active {
          top: 0;
          position: fixed;
        }
      }
    }

    // Nav D Fixed Below
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below {
      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: 0;
          position: fixed;
        }
      }
    }

    // Nav D Fixed Below + Top Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px);

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: 0;
          position: fixed;
        }
      }
    }


    // Nav D Fixed Above + Admin  Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above.admin-bar {
      padding-top: calc(32px + 82px);

      .desktop-navigation[data-style="d"] {
        top: 32px;
        position: absolute;

        &.active {
          top: 32px;
          position: fixed;
        }
      }
    }

    // Nav D Fixed Below + Admin Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below.admin-bar {
      padding-top: 32px;

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(32px);
          position: fixed;
        }
      }
    }


    // Nav D Fixed Below + Admin Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px);

      .page-template {
        padding-top: 82px;
      }

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(32px);
          position: fixed;
        }
      }
    }


    // Nav D Static Above + Admin  Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_above.admin-bar {
      padding-top: calc(32px + 82px);

      .desktop-navigation[data-style="d"] {
        top: 32px;
        position: absolute;

        &.active {
          top: 32px;
          position: fixed;
        }
      }
    }

    // Nav D Static Below + Admin Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_below.admin-bar {
      padding-top: 32px;

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(32px);
          position: fixed;
        }
      }
    }


    // Nav D Fixed Above + Top Bar Fixed
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 82px);

      .desktop-navigation[data-style="d"] {
        top: 56px;
        position: absolute;

        &.active {
          top: 56px;
          position: fixed;
        }
      }
    }

    // Nav D Fixed Below + Top Bar Fixed
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: 56px;

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(56px);
          position: fixed;
        }
      }
    }

    // Nav D Static Above + Top Bar Fixed
    &.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed {
      padding-top: calc(56px + 82px);

      .desktop-navigation[data-style="d"] {
        top: 56px;
        position: absolute;

        &.active {
          top: 56px;
          position: fixed;
        }
      }
    }

    // Nav D Static Below + Top Bar Fixed
    &.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed {
      padding-top: 56px;

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(56px);
          position: fixed;
        }
      }
    }


    // Nav D Fixed Above + Top Bar Fixed + Admin Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 82px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);
        position: absolute;

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }

    // Nav D Fixed Below + Top Bar Fixed + Admin Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px);

      .top-bar {
        top: 32px;
      }

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }


    // Nav D Static Above + Top Bar Fixed + Admin Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px + 82px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);
        position: absolute;

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }

    // Nav D Static Below + Top Bar Fixed + Admin Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
      padding-top: calc(32px + 56px);

      .top-bar {
        top: 32px;
      }

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;

        &.active {
          top: calc(32px + 56px);
          position: fixed;
        }
      }
    }


    // Nav D Static Above + Top Bar Static
    &.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 82px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);
        position: absolute;
      }
    }

    // Nav D Fixed Above + Top Bar Static
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static {
      padding-top: calc(56px + 82px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);
        position: absolute;

        &.active {
          position: fixed;
          top: 0;
        }
      }
    }

    // Nav D Fixed Above + Top Bar Static + Admin Bar
    &.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px + 82px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);
        position: absolute;

        &.active {
          position: fixed;
          top: 32px;
        }
      }
    }


    // Nav D Static Above + Top Bar Static + Admin Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px);

      .top-bar {
        top: 32px;
      }

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;
      }
    }

    // Nav D Static Below + Top Bar Static + Admin Bar
    &.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
      padding-top: calc(32px + 56px);

      .top-bar {
        top: 32px;
      }

      .page-template {
        padding-top: 82px;
      }

      .desktop-navigation[data-style="d"] {
        top: auto;
        position: absolute;
      }
    }

  }

  // Tablet
  @media (max-width: 1024px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="d"] {
        position: absolute;

        &.active {
          position: fixed;
        }
      }
    }

    // Nav D
    &.nav_style_d {
      padding-top: calc(100px);

      .desktop-navigation[data-style="d"] {
        top: 0;
      }
    }

    // Nav D  + Admin Bar
    &.nav_style_d.admin-bar {
      padding-top: calc(100px + 32px);

      .desktop-navigation[data-style="d"] {
        top: calc(32px);
      }
    }

    // Nav D +  Top Bar Static
    &.nav_style_d.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav D  + Top Bar Fixed
    &.nav_style_d.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);
      }
    }

    // Nav D + Admin Bar + Top Bar Static
    &.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);

        &.active {
          top: 32px;
        }
      }
    }

    // Nav D + Admin Bar + Top Bar Fixed
    &.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);
      }
    }

    // Nav D Fixed + Admin Bar + Top Bar Fixed
    &.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar {
      padding-top: calc(100px + 32px + 56px);

      .top-bar {
        top: 32px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(32px + 56px);

        &.active {
          top: calc(32px + 56px);
        }
      }
    }

    // Nav D Fixed + Top Bar Fixed
    &.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);

        &.active {
          top: calc(56px);
        }
      }
    }


  }

  // Mobile
  @media (max-width: 782px) {

    &.desktop_nav_fixed {
      .desktop-navigation[data-style="d"] {
        &.active {
          position: fixed;
        }
      }
    }

    // Nav D
    &.nav_style_d {
      padding-top: calc(100px);

      .desktop-navigation[data-style="d"] {
        position: absolute;
        top: 0;
      }
    }

    // Nav D  + Admin Bar
    &.nav_style_d.admin-bar {
      padding-top: calc(100px + 46px);

      .desktop-navigation[data-style="d"] {
        top: calc(46px);
      }
    }

    // Nav D +  Top Bar Static
    &.nav_style_d.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);

        &.active {
          top: 0;
        }
      }
    }

    // Nav D + Top Bar Fixed
    &.nav_style_d.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 56px);

      .top-bar {
        top: 0;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(56px);
      }
    }

    // Nav D + Admin Bar + Top Bar Static
    &.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(46px + 56px);

        &.active {
          top: 46px;
        }
      }
    }


    // Nav D + Admin Bar + Top Bar Fixed
    &.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed {
      padding-top: calc(100px + 46px + 56px);

      .top-bar {
        top: 46px;
      }

      .desktop-navigation[data-style="d"] {
        top: calc(46px + 56px);
      }
    }
  }

}